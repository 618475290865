<template>
  <div class="pt-32 xl:pt-48 mb-32">
    <h1
      class="text-5xl sm:text-6xl lg:text-8xl xl:text-9xl font-bold text-center"
    >
      {{ title }}
    </h1>
    <p
      class="
        max-w-4xl
        mx-auto
        text-center text-2xl
        xl:text-3xl
        text-gray-700
        mt-2
      "
    >
      {{ description }}
    </p>
    <div
      class="
        mt-16
        sm:mt-24
        grid grid-cols-2
        sm:grid-cols-3
        gap-6
        sm:gap-10
        lg:gap-16
        xl:gap-20
        container
        mx-auto
        px-6
        xl:px-12
      "
    >
      <button
        v-for="team in teams"
        :key="team.id"
        class="focus:outline-none"
        @click="openModel(team)"
      >
        <img
          :id="team.id"
          :src="team.image1"
          alt=""
          @mouseover="changeImage(`${team.id}`, `${team.image2}`)"
          @mouseleave="changeImage(`${team.id}`, `${team.image1}`)"
        />
      </button>
    </div>

    <div v-if="model" class="fixed z-50 inset-0 bg-white bg-opacity-95">
      <div
        persistent
        class="
          max-w-4xl
          mx-auto
          px-6
          xl:px-12
          text-center
          flex flex-col
          justify-center
          items-center
          h-full
        "
      >
        <img :src="selectedItem.image2" alt="" class="h-70 mx-auto object-cover" />
        <p class="text-5xl xl:text-6xl font-bold">
          {{ selectedItem.Name }}
        </p>
        <p
          class="
            text-xl
            xl:text-2xl
            mt-2
            text-center
            overflow-y-auto overflow-x-hidden
            relative
          "
        >
          {{ selectedItem.Description }}
        </p>
        <button
          @click="model = false"
          class="mt-6 focus:outline-none animate-bounce"
        >
          <svg
            width="24"
            height="45"
            viewBox="0 0 24 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2V36"
              stroke="black"
              stroke-width="4"
              stroke-linejoin="round"
            />
            <path
              d="M19 28L12 36L5 28"
              stroke="black"
              stroke-width="4"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="container mx-auto px-6 xl:px-12 text-center mt-16">
      <p class="text-5xl sm:text-6xl lg:text-8xl xl:text-9xl font-bold">
        {{ $t("People.People-1.title") }}
      </p>
      <p class="text-2xl xl:text-3xl max-w-3xl mx-auto">
        {{ $t("People.People-1.text") }}

        <router-link class="text-3xl font-bold" :to="`/${$i18n.locale}/job`">
          {{ $t("People.People-1.linke") }}
        </router-link>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: false,
      Local: this.$i18n.locale,
      teams: [],
      selectedItem: {
        Name: "",
        Description: "",
        image2: "",
      },
      title: "",
      description: "",
    };
  },
  created() {},
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.GetTeamMembers();
      await this.Getdata();
    },
    Getdata() {
      this.$http.teamService
        .GetContent(this.Local)
        .then((res) => {
          (this.title = res.data.Title),
            (this.description = res.data.Description);
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    changeImage(id, image) {
      document.getElementById(id).src = image;
    },
    openModel(item) {
      this.selectedItem = item;
      this.model = true;
    },
    GetTeamMembers() {
      this.$http.teamService
        .GetTeamMembers(this.Local)
        .then((res) => {
          let response = res.data;
          for (let i = 0; i < response.length; i++) {
            let obj = {};
            obj["id"] = `${response[i].id}`;
            obj["Name"] = `${response[i].Name}`;
            obj["Description"] = `${response[i].Description}`;
            obj[
              "image1"
            ] = `https://management.hroof.ly${response[i].image1.url}`;
            obj[
              "image2"
            ] = `https://management.hroof.ly${response[i].image2.url}`;

            this.teams.push(obj);
          }
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
  },
};
</script>
<style scoped>
.loader {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader.hidden {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>